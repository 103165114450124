@use '../../styles/Breakpoints.module.scss';

.solutions-list,
.stories-list {
    display: grid;
    row-gap: var(--padding-lr);
    column-gap: var(--padding-lr);
    grid-template-columns: 1fr 1fr;
    &.solutions-list-3col {
        grid-template-columns: 1fr 1fr 1fr;
    }

    li {
        width: 100%;
        display: flex;
        flex: 1;

        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        text-decoration: none;
    }

    h4 {
        margin-bottom: 2rem;
    }

    .story-content {
        position: relative;
        text-align: inherit;
        background-color: var(--layer-1_bg);
        color: inherit;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: 0.2s background-color linear;
        outline: 2px solid transparent;

        &:focus {
            outline: 2px solid var(--link);
        }

        &:hover {
            background-color: var(--layer-1_bg-hover);
        }
    }

    .description {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .icon-button {
        position: relative;
        left: -0.75rem;
    }

    .video-container {
        width: 100%;
        position: relative;
        background-color: var(--black-1);

        .video-thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 1;
            visibility: visible;
            transition: 0.2s linear opacity, 0.2s linear visibility;
        }

        &:hover,
        &.active {
            .video-thumbnail {
                opacity: 0;
                visibility: hidden;
            }
        }

        video {
            width: 100%;
        }
    }

    img {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: Breakpoints.$breakpoint-1) {
    .case-studies-section {
        h3 {
            /*
            font-size: $font-size-1;
            line-height: $line-height-1;
            */
            margin-bottom: 2rem;
        }
    }

    .solutions-list,
    .stories-list {
        flex-direction: column;

        li {
            margin-right: 0;
            margin-bottom: var(--padding-lr);

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        h4 {
            /*
            font-size: $font-size-1;
            font-weight: 600;
            */
            margin-bottom: 1rem;
        }
    }

    .stories-list {
        grid-template-columns: 100%;
    }

    .solutions-list {
        grid-template-columns: 100%;
    }
}