@use '../../styles/Breakpoints.module.scss';

.hero-text-background {
  background-color: var(--text-background);
}

.scroll-shield {
    position: absolute;
    z-index: 2;

    &.scroll-shield-bottom {
        bottom: 0;
    }

    &.scroll-shield-top {
        left: 0;
    }

    &.scroll-shield-right {
        right: 0;
    }

    &.scroll-shield-left {
        left: 0;
    }
    
}

.shield-container {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    height: 1px;
}

.interaction-shield {
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero-content-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.animation-container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;

  :global(.animation-background) {
    background-size: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .scene-container, :global(.scene-container) {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding-top: 12%;
  }
}

.scroll-interaction-icon {
  position: absolute;
  width: 96px;
  height: 76px;
  margin: -38px 0 0 -48px;
  top: 50%;
  left: 50%;
  z-index: 2;
  .mouse-icon-container {
    animation: mouseIconAnimation 2s linear 5 forwards;
    fill: var(--text);
    .mouse-icon-click {
      fill: var(--accent-1);
    }
  }
  
  .touch-icon-container {
    fill: var(--text);
    animation: touchIconAnimation 2s linear 5 forwards;
  }
}

.hero-cta-button {
  margin-top: 2rem;
}

.home-hero {
  display: flex;
  overflow: hidden;
  min-height: 880px;
  height: 66vh;
  position: relative;
  :global(.display-serif){
    margin-bottom: var(--padding-bottom);
  }
  :global(.hero .col){
    z-index: 2;
  }
}

@keyframes mouseIconAnimation {
  0% {
      transform: translate(8.5px, 8px);
      animation-timing-function: cubic-bezier(0, 0, .58, 1);
      opacity: 0;
    }
  
    50%,
    to {
      transform: translate(42.5px, 8px);
      opacity: 1
    }
  
    99% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
}

@keyframes touchIconAnimation {
  0% {
      transform: translate(7.748154px, 30.000004px);
      opacity: 0;
      animation-timing-function: cubic-bezier(0, 0, .58, 1);
    }
  
    50%,
    to {
      transform: translate(41.748154px, 30.000004px);
      opacity: 1
    }
  
    99% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
}

@media screen and (max-width: Breakpoints.$breakpoint-3) {
  .home-hero {
    min-height: 560px;
  }
  .scroll-interaction-icon {
    display: none;
  }
    .animation-container {
        align-items: center;
        justify-content: center;
    }
}