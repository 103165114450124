@use '../../styles/Themes.module.scss';

.performance-container {
    opacity: 0.33;
    &.active {
        opacity: 1;
    }
}

.metric-number {
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 0.75rem;
    color: var(--link);
}

.metrics-column>div {
    margin-bottom: 1.5rem;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.metric-bubble-container {
    align-items: center;
    color: var(--white-1);
    text-align: center;
    position: relative;
    @include Themes.light-contrast-selection;
    svg {
        width: 100%;
    }
    .metric-container {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        left: 0;
        p {
            max-width: none;
            width: 100%;
        }
    }
    .metric-number {
        font-size: 5rem;
        font-weight: 200;
        color: var(--white-1);
    }
}

/*
@media screen and (max-width: $breakpoint-0) {
    .performance-container .description-container {
        margin-right: 4rem;
    }

    .performance-metrics-container {
        font-size: $font-size-5;

        .metrics-column {
            min-width: 12rem;
        }

        .metric-bubble {
            height: 16rem;
            width: 16rem;

            .metric-number {
                font-size: $font-size-6;
                font-weight: $font-weight-1;
            }
        }

        h5 {
            margin-top: 0.5rem;
            font-size: $font-size-1;
        }
    }
}

@media screen and (max-width: $breakpoint-1) {
    .performance-container .description-container {
        margin-right: 2.5rem;
    }

    .performance-metrics-container {
        font-size: $font-size-3;

        .metrics-column {
            min-width: 12rem;
        }

        .metric-bubble {
            height: 12rem;
            width: 12rem;

            .metric-number {
                font-size: $font-size-5;
                font-weight: $font-weight-1;
            }
        }

        h5 {
            margin-top: 0.5rem;
            font-size: $font-size-1;
        }
    }
}

@media screen and (max-width: $breakpoint-2) {
    .performance-container .description-container {
        margin-right: 4rem;
    }

    .performance-metrics-container {
        font-size: $font-size-5;

        .metrics-column {
            min-width: 12rem;
        }

        .metric-bubble {
            height: 16rem;
            width: 16rem;

            .metric-number {
                font-size: $font-size-6;
                font-weight: $font-weight-1;
            }
        }

        h5 {
            margin-top: 0.5rem;
            font-size: $font-size-1;
        }
    }
}

@media screen and (max-width: $breakpoint-3) {
    .performance-container .description-container {
        margin-right: 0;

        p {
            max-width: 80%;
        }
    }

    .performance-metrics-container {
        font-size: $font-size-4;

        .metrics-column {
            min-width: 12rem;
        }

        .metric-bubble {
            height: 13rem;
            width: 13rem;

            .metric-number {
                font-size: $font-size-6;
                font-weight: $font-weight-1;
            }
        }

        h5 {
            margin-top: 0.5rem;
            font-size: $font-size-1;
        }
    }
}

@media screen and (max-width: $breakpoint-4) {

    .performance-metrics-container {
        font-size: $font-size-4;

        .metrics-column {
            min-width: 12rem;
        }

        .metric-bubble {
            height: 14rem;
            width: 14rem;

            .metric-number {
                font-size: $font-size-6;
                font-weight: $font-weight-1;
            }
        }

        h5 {
            margin-top: 0.5rem;
            font-size: $font-size-1;
        }
    }

    .performance-container {
        flex-direction: column-reverse;

        .performance-metrics-container {
            margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: $breakpoint-5) {
    .performance-container .description-container p {
        margin-right: 0;
        width: 100%;
        max-width: none;
    }

    .performance-metrics-container {
        .metric-bubble {
            height: 10.5rem;
            width: 10.5rem;

            .metric-number {
                font-size: $font-size-5;
                font-weight: $font-weight-1;
            }

            h5 {
                font-size: 0.75rem;
                line-height: 1rem;
            }
        }
    }
}
*/