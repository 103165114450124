.news-banner-container {
    text-align: center;
    width: 100%;
    z-index: 101;
    margin: auto;
    a {
        display: flex;
        justify-items: center;
        align-items: center;
        padding: 0.875rem var(--padding-lr);
        min-height: 48px;
        color: inherit;
        background-color: var(--layer-contrast_bg);
        color: var(--layer-contrast_text);
        border-bottom: 1px solid var(--layer-contrast_bg_hover);
        &:hover, &:active, &:focus {
            background-color: var(--layer-contrast_bg_hover);
            color: var(--layer-contrast_text);
            text-decoration: none;
        }
    }
}