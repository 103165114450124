@use '../styles/Themes.module.scss';

.subscribe-thanks-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.66);
    display: flex;
    justify-content: center;
    align-items: center;

    .subscribe-thanks-content {
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 1rem;
        background-color: var(--layer-contrast_bg);
        color: var(--layer-contrast_text);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        @include Themes.light-contrast-selection;

        .thanks-message {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h3 {
            margin-bottom: 0.5rem;
        }
    }
}

.maral-floating {
    animation: maralFloat 5s linear infinite;
    width: 11rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.subscribe-dismiss {
    width: 100%;
    max-width: none;
}

@keyframes maralFloat {

    from,
    to {
        top: -1rem;
    }

    50% {
        top: 0;
    }
}