.quote-ornament {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50%;
}

.quote-cursor {
    position: relative;
    left: -0.66rem;
    display: inline-block;
    vertical-align: middle;
    width: 0.125rem;
    height: 1.5rem;
    background-color: var(--link);
    animation: blink .75s step-end infinite;
    -webkit-animation: blink .75s step-end infinite;
    visibility: hidden;

    &.active {
        visibility: visible;
    }
}

.ghosts {
    visibility: hidden;
    overflow: hidden;
    display: flex;
    .ghost {
        min-width: 100%;
    }
}

.quote-container {
    z-index: 1;
    position: relative;
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 300;
}

.quote-content {
    position: relative;
    max-width: 48rem;
    margin-left: 1rem;
    margin-bottom: 2rem;

    .quote {
        position: absolute;
    }
}

.content-ghost-container {
    position: relative;
}

.name-container {
    margin-bottom: 0.5rem;
}

.quote-name-title {
    position: relative;
    max-width: 48rem;
    width: 80%;
    min-width: 20rem;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.22, 1, 0.36, 1) 1s;

    .quote-block {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 1;
        z-index: 1;
        background-color: var(--link);
    }

    .quote-name {
        font-weight: 600;
        margin-bottom: 0.33rem;
        .quote-block {
            transition: width 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.25s;
        }
    }

    .quote-title .quote-block {
        transition: width 1s cubic-bezier(0.22, 1, 0.36, 1) 0.35s;
    }

    &.active {
        opacity: 1;
        transition: opacity 0.25s linear;

        .quote-block {
            width: 0;
        }
    }

    .quote-name,
    .quote-title {
        transition: width 0.2s cubic-bezier(0.22, 1, 0.36, 1);
        display: inline-block;
        position: absolute;
    }

    .quote-title {
        font-weight: 400;
        line-height: 125%;
    }
}


.q-container {
    quotes: "“""”""‘""’";
    position: relative;

    &::before {
        content: open-quote;
        position: absolute;
        padding-right: 0.375rem;
        left: -1rem;
        top: 0;
        z-index: 0;
    }

    &::after {
        content: close-quote;
        position: relative;
        right: -0.25rem;
        top: 0;
        z-index: 0;
    }

    &.deleting {
        background-color: var(--link);
        &::before,
        &::after {
            background-color: transparent;
            color: var(--brand);
        }
    }
}


@keyframes blink {
    from,
    to {
        background-color: transparent
    }

    50% {
        background-color: var(--link);
    }
}